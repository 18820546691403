import { EventBusListenersMap, IEventBusMessage } from './types';

export const isEventBusMessage = <K extends keyof EventBusListenersMap>(
    message: unknown
): message is IEventBusMessage<K> => {
    return (
        message !== null &&
        typeof message === 'object' &&
        'message' in message &&
        message.message === 'event-bus-message'
    );
};

export const createEventBusMessage = <K extends keyof EventBusListenersMap>(
    type: K,
    ...args: Parameters<EventBusListenersMap[K]>
): IEventBusMessage<K> => {
    return {
        message: 'event-bus-message',
        type,
        payload: args,
    };
};
