export type WorkerServiceContext = string;
export type WorkerServiceHandler = string;
export type WorkerServiceAction = (...args: any) => Promise<any>;
export type WorkerServiceRequestPayload = any[];
export type WorkerServiceResponsePayload = any;

export type WorkerServiceActionDefinitions = Record<string, WorkerServiceAction>;

export type WorkerServiceActionsMap = Map<WorkerServiceHandler, Function>;
export type WorkerServiceRegisterCallback = () => WorkerServiceActionsMap;

export interface IWorkerServiceDefinition<T extends WorkerServiceActionDefinitions> {
    name: string;
    context: string;
    handlers: () => T;
}

export interface IWorkerServiceInstance<T extends WorkerServiceActionDefinitions> {
    actions: T & { _passthrough: T };
    register: WorkerServiceRegisterCallback;
}

export enum WorkerServiceMessageType {
    REQUEST = 'request',
    RESPONSE = 'response',
}

export interface IWorkerServiceMessageBase<T extends WorkerServiceMessageType = WorkerServiceMessageType> {
    message: 'worker-service-message';
    type: T;
}

export interface IWorkerServiceMessageRequest extends IWorkerServiceMessageBase<WorkerServiceMessageType.REQUEST> {
    context: WorkerServiceContext;
    handler: WorkerServiceHandler;
    payload: WorkerServiceRequestPayload;
}

export interface IWorkerServiceMessageResponse extends IWorkerServiceMessageBase<WorkerServiceMessageType.RESPONSE> {
    payload: WorkerServiceResponsePayload;
}

export type WorkerServiceMessage = IWorkerServiceMessageRequest | IWorkerServiceMessageResponse;

export interface IWorkerPipeSenderParams {
    context: WorkerServiceContext;
}
