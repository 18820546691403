import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import createDOMPurify from 'dompurify';

import packageJson from '../../../package.json';
import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { setMatchesCount } from '../../redux/slices/modals/preview-modal-slice';
import { storeLastPreviewData } from '../../redux/thunks/preview-thunk';
import { getExtensionURLPrefix } from '../../services/resources/resources-service';
import { getToastService } from '../../services/toast/toast-service';
import { getPointerStyles, getPreviewStyles } from '../../styles/preview';
import { isPopupMode, isWebMode } from '../../utils/extension-mode';
import { baseApi } from '../base-api';
import { AnswerPreviewType, IPreviewRequestParams, IPreviewResponse, PreviewType, ResponseStatus } from '../types';

const clientVersion = packageJson.version;
const DOMPurifier = createDOMPurify();

export const previewEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPreview: builder.mutation<IPreviewResponse, IPreviewRequestParams>({
            queryFn: async (arg, { dispatch, getState }, extraOptions, baseQuery) => {
                const { project, doc_id, query, source_id, source_type, preview_type } = arg;

                let resultData: IPreviewResponse = { content: '', type: null };

                // skip the request for PDF preview type
                if (preview_type === AnswerPreviewType.PDF) {
                    resultData.type = PreviewType.PDF;
                } else {
                    const state = getState() as IRootState;
                    const {
                        settings: { simulateCustomerProject, fullTicketAppUrl },
                        auth: { permissions },
                    } = state;

                    const shouldSimulateProjectId = !!fullTicketAppUrl && permissions.debugProactiveAccess;

                    const previewResponse = await baseQuery({
                        url: '/v1/documents/smart-preview',
                        method: 'POST',
                        body: {
                            customer_project_id: shouldSimulateProjectId ? simulateCustomerProject : project,
                            doc_id,
                            query,
                            client_version: clientVersion,
                            source_id,
                            source_type,
                        },
                    });

                    const { data, error, meta } = previewResponse as QueryReturnValue<
                        IPreviewResponse,
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >;

                    if (error) {
                        const message = i18n.t('error.api.preview', { ns: 'errors' });

                        if (error.status !== ResponseStatus.UNAUTHORIZED) {
                            getToastService().error(message, meta);
                        }

                        return {
                            error: {
                                status: -1,
                                data: message,
                            },
                        };
                    }
                    const { content, type } = data;

                    const matchesCount = content.match(/id="ask_ai_match\d+"/gm)?.length ?? 0;
                    dispatch(setMatchesCount(matchesCount));

                    resultData.content = DOMPurifier.sanitize(content, {
                        USE_PROFILES: { html: true },
                        WHOLE_DOCUMENT: true,
                        FORCE_BODY: true,
                        ALLOW_UNKNOWN_PROTOCOLS: true,
                        ADD_TAGS: ['meta', 'link', 'title', 'script', 'iframe'],
                    });

                    resultData.type = type;
                }

                await dispatch(storeLastPreviewData(arg));

                return { data: resultData };
            },
        }),
    }),
});

DOMPurifier.addHook('afterSanitizeElements', (node) => {
    const preloadScriptString =
        isWebMode() || isPopupMode() ? `<script src='${getExtensionURLPrefix()}/scripts/iframe-preload.js' />` : '';

    if (node.tagName?.toLowerCase() === 'body') {
        node.innerHTML = `
            ${getPreviewStyles()}
            ${node.innerHTML}
            ${getPointerStyles()}
            ${preloadScriptString}
        `;
    }
});

DOMPurifier.addHook('afterSanitizeAttributes', (node) => {
    if ('target' in node) {
        const link = node.getAttribute('href') ?? '';
        if (link.startsWith('#')) {
            node.setAttribute('href', `about:srcdoc${link.replace(/ /g, '')}`);
        } else {
            node.setAttribute('target', '_blank');
        }
    } else if ('src' in node) {
        let src = node.getAttribute('src') ?? '';
        // When a URL in a src attribute starts with "//" - it should be the same method the page was loaded
        // because we're in extensions, we need to set it as HTTPS
        if (src.startsWith('//')) {
            src = 'https:' + src;
        }

        node.setAttribute('src', src);
    }
});

export const { useGetPreviewMutation } = previewEndpoint;
