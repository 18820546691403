import { EndUserScreenDimensions } from '../services/browser/types';

import { isPopupMode } from './extension-mode';

export const getEndUserScreenDimensions = async (): Promise<EndUserScreenDimensions> => {
    if (isPopupMode()) {
        const win = await chrome.windows.getCurrent();
        let top = window.screenY;
        let left = window.screenX;
        let width = window.screen.width;
        let height = window.screen.height;

        if ('top' in win) {
            top = (win as any).top;
        }

        if ('left' in win) {
            left = (win as any).left;
        }

        if ('width' in win) {
            width = (win as any).width;
        }

        if ('height' in win) {
            height = (win as any).height;
        }

        return {
            top,
            left,
            width,
            height,
        };
    } else {
        return {
            top: window.screenY,
            left: window.screenX,
            height: window.screen.height,
            width: window.screen.width,
        };
    }
};

export const isNativeShareSupported = (): boolean =>
    navigator !== undefined && 'share' in navigator && 'canShare' in navigator;

// https://github.com/me-ruhin/device-detector
export function getDeviceType(userAgent?: string, screenWidth?: number): 'mobile' | 'tablet' | 'desktop' | 'unknown' {
    const ua = userAgent || (typeof window !== 'undefined' && window.navigator.userAgent) || '';
    const width = screenWidth !== undefined ? screenWidth : typeof window !== 'undefined' && window.innerWidth;

    const isMobile = /Mobile|Android|iP(hone|od)/.test(ua) || (typeof width === 'number' && width <= 767);
    const isTablet = /Tablet|iPad/.test(ua) || (typeof width === 'number' && width >= 768 && width <= 1024);
    const isDesktop = (!isMobile && !isTablet) || (typeof width === 'number' && width > 1024);

    if (isMobile) {
        return 'mobile';
    } else if (isTablet) {
        return 'tablet';
    } else if (isDesktop) {
        return 'desktop';
    }

    return 'unknown';
}
