import { getLogger } from '../../utils/logger';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

import { abortManager } from './abort-manager/abort-manager';
import { getBackgroundFetchResponse, normalizeHeaders, normalizeRequestUrl } from './helpers';
import { BackgroundFetchPayload, IBackgroundFetchResponse } from './types';

const name = 'background-fetch';
const logger = getLogger(name);

const backgroundFetch = async (payload: BackgroundFetchPayload): Promise<IBackgroundFetchResponse> => {
    const { url, requestId, options: inputOptions } = payload;

    const abortController = new AbortController();
    abortManager.addController(requestId, abortController);

    try {
        const fetchResponse = await fetch(url, {
            ...inputOptions,
            credentials: 'omit',
            signal: abortController.signal,
        });

        const response = await getBackgroundFetchResponse(fetchResponse);

        if (!response.options.ok) {
            return { options: response.options };
        }

        return response;
    } catch (e) {
        abortManager.abort(requestId);
        logger.error(`Error on fetch: ${e}`);

        return {
            options: {
                ok: false,
                statusText: 'Error on fetch',
            },
        };
    } finally {
        abortManager.removeController(requestId);
    }
};

const service = createWorkerService({
    name,
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        backgroundFetch,
        _abortBackgroundFetch: async (requestId: string) => abortManager.abort(requestId),
        _removeAbortController: async (requestId: string) => abortManager.removeController(requestId),
    }),
});

export const backgroundFetchService = service.actions;
export const registerBackgroundFetchService = service.register;

export const fetchInBackground = async (input: RequestInfo | URL, init?: RequestInit) => {
    const url = normalizeRequestUrl(input);
    const requestId = url;
    // trigger abort background fetch
    const handleAbortSignal = () => {
        return backgroundFetchService._abortBackgroundFetch(requestId);
    };

    const { signal: initSignal, ...options } = init ?? {};

    const requestHeaders = normalizeHeaders(options?.headers);

    try {
        if (initSignal && !initSignal.aborted) {
            initSignal.addEventListener('abort', handleAbortSignal);
        }

        const fetchPayload: BackgroundFetchPayload = {
            url,
            requestId,
            options: {
                ...options,
                headers: requestHeaders,
            },
        };

        return await nonStreamFetch(fetchPayload, initSignal);
    } catch (e) {
        logger.error(`Failed to send message to fetch: ${e}`);

        return Response.error();
    } finally {
        if (initSignal) {
            initSignal.removeEventListener('abort', handleAbortSignal);
        }
    }
};

const nonStreamFetch = async (payload: BackgroundFetchPayload, _?: null | AbortSignal): Promise<Response> => {
    const response = await backgroundFetchService.backgroundFetch(payload);

    if (response.options?.ok === false) {
        return Response.error();
    }

    let data: string | null = null;

    if (response.data) {
        data = typeof response.data === 'string' ? response.data : JSON.stringify(response.data);
    }

    return new Response(data, response.options);
};
