import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddKnowledgeFormValues } from '../../../components/AskAiKnowledge/AddKnowledgeModal/types';

type KbInitFormValues = Pick<AddKnowledgeFormValues, 'title' | 'answer'>;

export interface IAskAiKbModalSliceState {
    visible: boolean;
    initFormValues: KbInitFormValues | null;
}

const initialState: IAskAiKbModalSliceState = {
    visible: false,
    initFormValues: null,
};

export const askAiKbModalSlice = createSlice({
    name: 'ask-ai-kb-modal',
    initialState,
    reducers: {
        _openAskAiAddKnowledgeModal(state, action: PayloadAction<KbInitFormValues | null>) {
            state.visible = true;
            state.initFormValues = action.payload;
        },
        _closeAskAiAddKnowledgeModal(state) {
            state.visible = false;
            state.initFormValues = null;
        },
    },
});

// we have a thunk with send metrics in it for this
export const { _closeAskAiAddKnowledgeModal, _openAskAiAddKnowledgeModal } = askAiKbModalSlice.actions;
