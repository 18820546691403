import {
    IWorkerServiceMessageRequest,
    IWorkerServiceMessageResponse,
    WorkerServiceMessage,
    WorkerServiceMessageType,
} from '../types';

export const isWorkerServiceMessage = (message: unknown): message is WorkerServiceMessage => {
    return (
        message !== null &&
        typeof message === 'object' &&
        'message' in message &&
        message.message === 'worker-service-message'
    );
};

export const isWorkerServiceRequestMessage = (message: unknown): message is IWorkerServiceMessageRequest => {
    return isWorkerServiceMessage(message) && message.type === WorkerServiceMessageType.REQUEST;
};

export const isWorkerServiceResponseMessage = (message: unknown): message is IWorkerServiceMessageResponse => {
    return isWorkerServiceMessage(message) && message.type === WorkerServiceMessageType.RESPONSE;
};
