import { isEventBusMessage } from '../messages';
import { EventBusListenersMap, IEventBus } from '../types';

type ListenersMap = {
    [K in keyof EventBusListenersMap]?: Set<EventBusListenersMap[K]>;
};

export default class EventBusRuntime implements IEventBus<EventBusListenersMap> {
    private listenersMap: ListenersMap = {};

    constructor() {
        chrome.runtime.onMessage.addListener(this.onMessage);
    }

    private onMessage = <K extends keyof EventBusListenersMap>(message: unknown) => {
        if (!isEventBusMessage<K>(message)) {
            return;
        }

        const { type, payload } = message;

        const listeners = this.listenersMap[type];

        if (listeners) {
            listeners.forEach((listener) => listener.apply(this, payload));
        }
    };

    private getListeners<K extends keyof EventBusListenersMap>(type: K): Set<EventBusListenersMap[K]> {
        const listeners = this.listenersMap[type];

        if (listeners) {
            return listeners;
        }

        const newListeners = new Set<EventBusListenersMap[K]>();
        this.listenersMap[type] = newListeners;

        return newListeners;
    }

    public addListener<K extends keyof EventBusListenersMap>(type: K, listener: EventBusListenersMap[K]) {
        const listeners = this.getListeners(type);
        listeners.add(listener);
    }

    public removeListener<K extends keyof EventBusListenersMap>(type: K, listener: EventBusListenersMap[K]) {
        const listeners = this.getListeners(type);
        listeners.delete(listener);
    }
}
