import { isWebMode } from '../../utils/extension-mode';

let extensionURLPrefix = '';

export const initializeResourcesService = async () => {
    if (isWebMode()) {
        return;
    }

    extensionURLPrefix = chrome.runtime.getURL('').slice(0, -1);
};

export const getExtensionURLPrefix = () => extensionURLPrefix;
