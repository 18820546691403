import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DatesFilterRelativeName } from '../../api/types';

import { useAppSelector } from './app-hooks';

export const useFilters = () => {
    const { t } = useTranslation('translations');
    const { disabledSources, selectedSmartFilters, selectedDatesFilter } = useAppSelector((state) => state.settings);

    const appliedFiltersCount = useMemo(() => {
        let counter = 0;
        if (disabledSources.length > 0) {
            counter++;
        }

        if (selectedSmartFilters.length > 0) {
            counter++;
        }

        if (selectedDatesFilter.relative_name !== DatesFilterRelativeName.ANY_TIME) {
            counter++;
        }

        return counter;
    }, [disabledSources, selectedDatesFilter, selectedSmartFilters]);

    const appliedFiltersLabel = appliedFiltersCount < 10 ? `${appliedFiltersCount}` : t('filters.badge.more-than-nine');

    return { appliedFiltersCount, appliedFiltersLabel };
};
