import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { SHARED_PREVIEW_CACHE_KEY } from 'answers-core/src/api/consts';
import { useGetAnnotationResultQuery } from 'answers-core/src/api/endpoints/annotate-answers-endpoint';
import { useGetPreviewMutation } from 'answers-core/src/api/endpoints/preview-endpoint';
import { IAnswerResponse } from 'answers-core/src/api/types';
import { NotificationBanner } from 'answers-core/src/components/NotificationBanner/NotificationBanner';
import { InformativeEmptyState } from 'answers-core/src/components/Placeholders/InformativeEmptyState/InformativeEmptyState';
import { NoRelevantResultsPlaceholder } from 'answers-core/src/components/Placeholders/NoRelevantResults/NoRelevantResultsPlaceholder';
import { useAnswers } from 'answers-core/src/redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { useProject } from 'answers-core/src/redux/hooks/settings-hooks';
import { previewAnswer } from 'answers-core/src/redux/thunks/preview-thunk';
import { getAnswerQuery } from 'answers-core/src/utils/answer';

import { SubmitAnnotation } from '../AnnotateAnswer/Actions/SubmitAnnotation';
import { AnnotateAnswer } from '../AnnotateAnswer/AnnotateAnswer';
import { SortAnswersList } from '../SortAnswersList/SortAnswersList';

import styles from './AnnotateAnswersList.module.scss';

export const AnnotateAnswersList: React.FC = () => {
    const dispatch = useAppDispatch();
    const [, { isLoading, isUninitialized }] = useAnswers();
    const { sorted_chunks: chunks } = useAppSelector((state) => state.annotateAnswers);
    const [urlSearchParams] = useSearchParams();
    const annotationId = urlSearchParams.get('annotation_id') || '';

    const { isLoading: isLoadingAnnotation } = useGetAnnotationResultQuery(
        { annotationId },
        {
            skip: !Boolean(annotationId),
        }
    );
    const [getPreview] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const { project } = useProject();

    const previewAnswerHandler = useCallback(
        (_answer: unknown) => {
            if (!_answer) {
                return;
            }
            const answer = _answer as IAnswerResponse;

            const { doc_id, source_id, source_type, preview_type } = answer;
            const query = getAnswerQuery(answer);

            getPreview({
                doc_id,
                query,
                project,
                source_id,
                source_type,
                preview_type,
            });
            dispatch(previewAnswer(answer));
        },
        [dispatch, getPreview, project]
    );

    const renderList = () => {
        if (isLoading || isLoadingAnnotation) {
            return (
                <div className={styles.spinner}>
                    <Spinner />
                </div>
            );
        }

        return !chunks?.length ? (
            <NoRelevantResultsPlaceholder />
        ) : (
            <>
                <div data-testid="annotate-answers.list" className={styles.annotateList}>
                    <SortAnswersList annotationId={annotationId} />
                    {chunks.map((chunk) => (
                        <AnnotateAnswer key={chunk.chunk_id} chunk={chunk} onClick={previewAnswerHandler} />
                    ))}
                </div>
                <SubmitAnnotation />
            </>
        );
    };

    const initialPlaceholderVisible = chunks?.length === 0 && isUninitialized;

    return (
        <div className={styles.wrapper}>
            <NotificationBanner />
            {initialPlaceholderVisible ? <InformativeEmptyState className={styles.placeholder} /> : renderList()}
        </div>
    );
};
