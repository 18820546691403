import { AbstractEventHandler } from '../../abstract-event-handler';

import { isSelectionChangeEvent } from './helpers';

export class OnSelectionChangeEvent extends AbstractEventHandler {
    register() {
        chrome.runtime.onMessage.addListener((event: unknown, sender) => {
            if (isSelectionChangeEvent(event) && sender.tab?.id) {
                this.emitter.emit('selectionchange', sender.tab.id, event.payload);
            }
        });
    }
}
