import React, { useEffect, useRef } from 'react';
import cx from 'clsx';
import Quill from 'quill';

import { isContentMode } from '../../../../../utils/extension-mode';
import { htmlToMarkdown, markdownToHtml } from '../../../../../utils/markdowns-convertor';
import { handleQuillSelection } from '../../../../Controls/TextEditor/utils';

import 'quill/dist/quill.snow.css';
import styles from './RichTextEditor.module.scss';

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
];

const quillOptions = {
    theme: 'snow',
    modules: {
        toolbar: TOOLBAR_OPTIONS,
    },
};

export type StyledEditorProps = {
    id?: string;
    initialValue: string;
    onChange?: (value: string) => void;
    error?: boolean;
    isDisabled?: boolean;
    forceSpellcheck?: boolean;
};

export const RichTextEditor = ({
    error,
    id,
    onChange,
    initialValue,
    isDisabled,
    forceSpellcheck,
}: StyledEditorProps) => {
    const quillRef = useRef<Quill | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    // initialize Quill editor
    // should only run once and before adding any event listeners
    useEffect(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
        quillRef.current = new Quill(editorContainer, quillOptions);

        if (forceSpellcheck) {
            quillRef.current.root.setAttribute('spellcheck', 'true');
        }

        quillRef.current.clipboard.dangerouslyPasteHTML(markdownToHtml(initialValue));

        // fixes the issue with incorrect cursor position in sidebar
        if (isContentMode()) {
            handleQuillSelection(quillRef.current);
        }

        // Cleanup on component unmount
        return () => {
            quillRef.current = null;
            container.innerHTML = '';
        };
    }, [initialValue, forceSpellcheck]);

    useEffect(() => {
        const handleTextChange = () => {
            if (!quillRef.current) {
                return;
            }
            const html = quillRef.current.getSemanticHTML();

            onChange?.(htmlToMarkdown(html, { headingStyle: 'atx' }));
        };

        if (!quillRef.current) {
            return;
        }
        quillRef.current.on(Quill.events.TEXT_CHANGE, handleTextChange);

        return () => {
            quillRef.current?.off(Quill.events.TEXT_CHANGE, handleTextChange);
        };
    }, [onChange]);

    useEffect(() => {
        quillRef.current?.enable(!isDisabled);
    }, [isDisabled]);

    return (
        <div
            id={id}
            className={cx(styles.editor, error && styles.error, isDisabled && styles.disabled)}
            ref={containerRef}
        ></div>
    );
};
