import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../../i18n';
import { IRootState } from '../../../redux/core-store';
import { getToastService } from '../../../services/toast/toast-service';
import { getUserProject } from '../../../utils/user';
import { baseApi } from '../../base-api';
import { ApiTagTypes, ResponseStatus } from '../../types';

import { API_ASK_AI_KB_URL } from './consts';
import {
    IAddKnowledgeApi,
    IAddKnowledgeParams,
    IAddKnowledgeResponseApi,
    ICreateCollectionParams,
    ICreateCollectionResponseApi,
    IGetCollectionsResponseApi,
} from './types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const askAiKbEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        addKnowledge: builder.mutation<IAddKnowledgeResponseApi, IAddKnowledgeParams>({
            queryFn: async (arg, { getState }, _, baseQuery) => {
                const state = getState() as IRootState;
                const project = getUserProject(state);

                const body: IAddKnowledgeApi = {
                    title: arg.title,
                    content: arg.content,
                    access_level: arg.accessLevel,
                    collection_ids: arg.collectionIds,
                };

                const queryResponse = await baseQuery({
                    url: `${API_ASK_AI_KB_URL}/articles/${project}`,
                    method: 'POST',
                    body: body,
                });

                const { data, error, meta } = queryResponse as QueryReturnValue<
                    IAddKnowledgeResponseApi,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.ask-ai-kb.add', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                return { data };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
        createCollection: builder.mutation<ICreateCollectionResponseApi['collection'], ICreateCollectionParams>({
            queryFn: async (arg, { getState }, __, baseQuery) => {
                const state = getState() as IRootState;
                const customerProjectId = getUserProject(state);

                const body = { name: arg.name };

                const queryResponse = await baseQuery({
                    body,
                    url: `${API_ASK_AI_KB_URL}/collections/${customerProjectId}`,
                    method: 'POST',
                });

                const { data, error, meta } = queryResponse as QueryReturnValue<
                    ICreateCollectionResponseApi,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const isValidationError = error.status === ResponseStatus.VALIDATION_FAILED;
                    const message = isValidationError
                        ? i18n.t('error.api.ask-ai-kb.collection-name-not-unique', {
                              ns: 'errors',
                              collection_name: arg.name,
                          })
                        : i18n.t('error.api.ask-ai-kb.create-collection', { ns: 'errors' });

                    if (isValidationError) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                return { data: data.collection };
            },
        }),
        getCollections: builder.query<IGetCollectionsResponseApi['collections'], void>({
            queryFn: async (_, { getState }, __, baseQuery) => {
                const state = getState() as IRootState;
                const customerProjectId = getUserProject(state);

                const queryResponse = await baseQuery({
                    url: `${API_ASK_AI_KB_URL}/collections/${customerProjectId}`,
                    method: 'GET',
                });

                const { data, error } = queryResponse as QueryReturnValue<
                    IGetCollectionsResponseApi,
                    FetchBaseQueryError
                >;

                if (error) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.get-collections', { ns: 'errors' }),
                        },
                    };
                }

                return { data: data.collections };
            },
        }),
    }),
});

export const { useAddKnowledgeMutation, useCreateCollectionMutation, useGetCollectionsQuery } = askAiKbEndpoint;
