import { isWebMode } from '../../../utils/extension-mode';

import {
    IWorkerServiceDefinition,
    IWorkerServiceInstance,
    WorkerServiceActionDefinitions,
    WorkerServiceActionsMap,
    WorkerServiceRegisterCallback,
} from './types';

export const createWorkerService = <T extends WorkerServiceActionDefinitions>(
    definition: IWorkerServiceDefinition<T>
): IWorkerServiceInstance<T> => {
    const { name: serviceName, context, handlers } = definition;
    const handlersMap: WorkerServiceActionsMap = new Map();

    const handlerProxies = Object.entries(handlers()).reduce((result, [name, handler]) => {
        const handlerId = `${serviceName}/${name}`;
        handlersMap.set(handlerId, handler);

        result[name] = async (...args: Parameters<typeof handler>) => {
            if (isWebMode()) {
                const result = await handler(...args);

                return result;
            }

            const { workerPipeSenderFactory } = await import(
                /* webpackMode: "eager" */
                './pipe/worker-pipe-sender'
            );
            const sender = workerPipeSenderFactory.get(context);

            const response = await sender.send(handlerId, args);

            return response;
        };

        return result;
    }, {} as WorkerServiceActionDefinitions);

    const register: WorkerServiceRegisterCallback = () => handlersMap;

    const actions = {
        ...(handlerProxies as T),
        _passthrough: definition.handlers(),
    };

    return {
        actions,
        register,
    };
};
