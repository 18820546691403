import {
    IWorkerPipeSenderParams,
    WorkerServiceContext,
    WorkerServiceHandler,
    WorkerServiceRequestPayload,
} from '../types';

import { isWorkerServiceResponseMessage } from './messages';
import { workerServiceMessagesFactory } from './messages-factory';

class WorkerPipeSender {
    private readonly context: WorkerServiceContext;

    constructor(params: IWorkerPipeSenderParams) {
        const { context } = params;

        this.context = context;
    }

    async send(handler: WorkerServiceHandler, payload: WorkerServiceRequestPayload) {
        const requestMessage = workerServiceMessagesFactory.createRequestMessage({
            context: this.context,
            handler,
            payload,
        });

        const result = await chrome.runtime.sendMessage(requestMessage);

        if (isWorkerServiceResponseMessage(result)) {
            return result.payload;
        }
    }
}

class WorkerPipeSenderFactory {
    private senders: Map<string, WorkerPipeSender> = new Map();

    public get(context: WorkerServiceContext): WorkerPipeSender {
        const sender = this.senders.get(context);

        if (sender) {
            return sender;
        }

        const newSender = new WorkerPipeSender({ context });
        this.senders.set(context, newSender);

        return newSender;
    }
}

export const workerPipeSenderFactory = new WorkerPipeSenderFactory();
