import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Button, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';

import {
    useAddKnowledgeMutation,
    useCreateCollectionMutation,
} from '../../../api/endpoints/ask-ai-kb/ask-ai-kb-endpoint';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { openSuccessModal } from '../../../redux/slices/modals/success-modal-slice';
import { closeAskAiAddKnowledgeModal } from '../../../redux/thunks/ask-ai-kb-thunk';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { ModalContainer } from '../../Modals/Container/ModalContainer';
import { AddKnowledgeConfirmModal } from '../AddKnowledgeConfirmModal/AddKnowledgeConfirmModal';
import { accessLevelOptions } from '../AddKnowledgeForm/AccessLevel/access-level-options';
import { AddKnowledgeForm } from '../AddKnowledgeForm/AddKnowledgeForm';

import { AddKnowledgeFormValues } from './types';
import { validationSchema } from './validationSchema';

import styles from './AddKnowledgeModal.module.scss';

export const AddKnowledgeModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const isModalOpen = useAppSelector((state) => state.modals.askAiKb.visible);
    const initFormValues = useAppSelector((state) => state.modals.askAiKb.initFormValues);
    const {
        onOpen: onConfirmOpen,
        onClose: onConfirmClose,
        isOpen: confirmOpen,
    } = useDisclosure({ defaultIsOpen: false });
    const confirmPromiseRef = useRef<{ resolve: (confirmationResult: boolean) => void } | null>(null);
    const closeOnCancel = () => {
        if (confirmPromiseRef.current) {
            confirmPromiseRef.current.resolve(false);
            confirmPromiseRef.current = null;
        }

        dispatch(closeAskAiAddKnowledgeModal());
    };

    const [createCollection, { isLoading: isCreateCollectionProcessing }] = useCreateCollectionMutation();
    const [addKnowledge, { isLoading: isKnowledgeProcessing }] = useAddKnowledgeMutation();

    const sendArticleAddedMetrics = useCallback(() => {
        dispatch(
            sendMetrics({
                event: MixpanelEvent.ASK_AI_KB_ARTICLE_ADDED,
                meta: {
                    question: initFormValues?.title,
                },
            })
        );
    }, [dispatch, initFormValues?.title]);

    const showSuccessModal = useCallback(() => {
        const successMessage = {
            header: t('ask-ai-kb.add-to-kb'),
            message: t('ask-ai-kb.submitting-knowledge-success.title'),
            description: (
                <span style={{ textAlign: 'center', display: 'inline-block' }}>
                    {t('ask-ai-kb.submitting-knowledge-success.description')}
                </span>
            ),
        };

        batch(() => {
            dispatch(closeAskAiAddKnowledgeModal());
            dispatch(openSuccessModal(successMessage));
        });
    }, [dispatch, t]);

    const handleSubmit = useCallback(
        async (values: AddKnowledgeFormValues, formik: FormikHelpers<AddKnowledgeFormValues>) => {
            // open confirm modal and wait for user action
            onConfirmOpen();
            const confirm = new Promise<boolean>((resolve) => {
                confirmPromiseRef.current = { resolve };
            });

            const closeConfirmAndResetForm = () => {
                onConfirmClose();
                // cleanup
                confirmPromiseRef.current = null;
                // should fix issue with the rich-text editor
                formik.resetForm({ values });
            };

            const confirmed = await confirm;

            if (!confirmed) {
                closeConfirmAndResetForm();

                return;
            }

            const createCollections = async (names: string[]) => {
                const promises = names.map((name) => createCollection({ name }).unwrap());

                return await Promise.all(promises);
            };

            try {
                let newCollectionIds: string[] = [];
                const collectionsToCreate = values.collections.filter(({ __isNew__ }) => !!__isNew__);

                if (collectionsToCreate.length) {
                    const names = collectionsToCreate.map(({ value }) => value);
                    const newCollections = await createCollections(names);

                    newCollectionIds = newCollections.map(({ collection_id }) => collection_id);
                }

                const existedCollectionIds = values.collections
                    .filter(({ __isNew__ }) => !__isNew__)
                    .map(({ value }) => value);

                const collectionIds = newCollectionIds.concat(existedCollectionIds);

                await addKnowledge({
                    collectionIds,
                    title: values.title,
                    content: values.answer,
                    accessLevel: values.accessLevel.value,
                }).unwrap();

                sendArticleAddedMetrics();
                showSuccessModal();
            } catch {
                // do nothing, the error message will be shown in 'toast'
            } finally {
                closeConfirmAndResetForm();
            }
        },
        [onConfirmOpen, onConfirmClose, createCollection, addKnowledge, sendArticleAddedMetrics, showSuccessModal]
    );

    const disableFormActions = isCreateCollectionProcessing || isKnowledgeProcessing;

    const initialValues: AddKnowledgeFormValues = {
        answer: initFormValues?.answer ?? '',
        title: initFormValues?.title ?? '',
        accessLevel: accessLevelOptions[0],
        collections: [],
    };

    const renderFormContent = () => {
        return (
            <ModalContent>
                <Form className={styles.wrapper}>
                    <div className={styles.inner}>
                        <AddKnowledgeForm />
                    </div>
                    <ModalFooter className={styles.controls}>
                        <Button variant="secondary" onClick={closeOnCancel} isDisabled={disableFormActions}>
                            {t('button.cancel')}
                        </Button>
                        <Button variant="primary" type="submit" isLoading={disableFormActions}>
                            {t('ask-ai-kb.add-to-kb')}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalContent>
        );
    };

    const renderConfirmContent = () => {
        const handleConfirmClick = () => {
            confirmPromiseRef?.current?.resolve(true);
        };

        const handleRefuseClick = () => {
            confirmPromiseRef?.current?.resolve(false);
        };

        return (
            <ModalContent className={styles.confirmContainer}>
                <div className={styles.wrapper}>
                    <div className={styles.inner}>
                        <AddKnowledgeConfirmModal />
                    </div>
                    <ModalFooter className={styles.controls}>
                        <Button variant="secondary" onClick={handleRefuseClick} isDisabled={disableFormActions}>
                            {t('button.back')}
                        </Button>
                        <Button variant="primary" onClick={handleConfirmClick} isLoading={disableFormActions}>
                            {t('button.confirm')}
                        </Button>
                    </ModalFooter>
                </div>
            </ModalContent>
        );
    };

    const modalVariant = confirmOpen ? 'confirm' : 'add-knowledge';
    const content = confirmOpen ? renderConfirmContent() : renderFormContent();

    return (
        <ModalContainer
            variant={modalVariant}
            isOpen={isModalOpen}
            onClose={closeOnCancel}
            motionPreset="scale"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                {content}
            </Formik>
        </ModalContainer>
    );
};
