import { OnCommandEvent } from './events/on-command-event';
import { OnSelectionChangeEvent } from './events/on-selection-change/on-selection-change-event';
import { OnTokenUpdatedEvent } from './events/on-token-updated-event';
import { TabUrlChangedEvent } from './events/tab-url-changed-event';
import { AbstractEventHandler } from './abstract-event-handler';
import { createEventBusMessage } from './messages';
import { EventBusListenersMap, IEventBusEmitter } from './types';

class EventBusEmitter {
    public registerEventHandler<H extends AbstractEventHandler>(handler: new (emitter: IEventBusEmitter) => H) {
        const instance = new handler(this);
        instance.register();
    }

    public async emit<K extends keyof EventBusListenersMap>(
        type: K,
        tabId: number | undefined,
        ...args: Parameters<EventBusListenersMap[K]>
    ) {
        const message = createEventBusMessage(type, ...args);

        const sendMessage = (tId: number | undefined) => {
            if (tId === undefined) {
                return;
            }

            chrome.tabs.sendMessage(tId, message).catch(() => {
                // it might be nobody listening for the message (page discarded, loading, etc.)
            });
        };

        if (tabId !== undefined) {
            sendMessage(tabId);
        } else {
            const tabs = await chrome.tabs.query({ url: ['http://*/*', 'https://*/*'] });

            tabs.forEach((tab) => {
                sendMessage(tab.id);
            });
        }
    }
}

export const eventBusEmitter = new EventBusEmitter();

export const registerEvents = () => {
    eventBusEmitter.registerEventHandler(OnCommandEvent);
    eventBusEmitter.registerEventHandler(TabUrlChangedEvent);
    eventBusEmitter.registerEventHandler(OnTokenUpdatedEvent);
    eventBusEmitter.registerEventHandler(OnSelectionChangeEvent);
};
