import {
    IWorkerServiceMessageRequest,
    IWorkerServiceMessageResponse,
    WorkerServiceContext,
    WorkerServiceHandler,
    WorkerServiceMessageType,
    WorkerServiceRequestPayload,
    WorkerServiceResponsePayload,
} from '../types';

export const workerServiceMessagesFactory = {
    createRequestMessage: ({
        context,
        handler,
        payload,
    }: {
        context: WorkerServiceContext;
        handler: WorkerServiceHandler;
        payload: WorkerServiceRequestPayload;
    }): IWorkerServiceMessageRequest => ({
        message: 'worker-service-message',
        type: WorkerServiceMessageType.REQUEST,
        context,
        handler,
        payload,
    }),
    createResponseMessage: (payload: WorkerServiceResponsePayload): IWorkerServiceMessageResponse => ({
        message: 'worker-service-message',
        type: WorkerServiceMessageType.RESPONSE,
        payload,
    }),
};
