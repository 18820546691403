import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { useGetCollectionsQuery } from '../../../../../api/endpoints/ask-ai-kb/ask-ai-kb-endpoint';
import { IArticleCollection } from '../../../../../api/endpoints/ask-ai-kb/types';
import { SelectOption } from '../../Select/types';

import { classNames, getStyles } from './custom-styles';

import styles from '../../Select/SelectStyles.module.scss';

const collectionToOption = (collection: IArticleCollection): SelectOption => ({
    label: collection.name,
    value: collection.collection_id,
});

type CustomSelectProps = Pick<Props<SelectOption, true>, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

export interface CollectionsMultiSelectInputProps extends CustomSelectProps {
    id?: string;
    name?: string;
    isInvalid?: boolean;
    isDisabled?: boolean;
    onCreateOption?: (inputValue: string) => void;
}

export const CollectionsMultiSelectInput: React.FC<CollectionsMultiSelectInputProps> = ({ isInvalid, ...rest }) => {
    const { t } = useTranslation('translations');
    const {
        isLoading,
        isFetching,
        data: collections,
    } = useGetCollectionsQuery(undefined, { refetchOnMountOrArgChange: true });

    const options = useMemo(() => (collections ?? []).map(collectionToOption), [collections]);

    const loadOptions = useCallback(
        async (inputValue: string) => {
            return !inputValue
                ? options
                : options.filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()));
        },
        [options]
    );

    const renderNoOptionsMessage = useCallback(() => t('ask-ai-kb.collections-no-options'), [t]);
    const renderCreateOptionLabel = useCallback(
        () => <span className={styles.createOptionLabel}>{t('ask-ai-kb.collections-add-new')}</span>,
        [t]
    );

    return (
        <AsyncCreatableSelect
            isMulti
            cacheOptions
            allowCreateWhileLoading
            isLoading={isLoading || isFetching}
            aria-invalid={isInvalid}
            styles={getStyles}
            classNames={classNames}
            defaultOptions={options}
            loadOptions={loadOptions}
            noOptionsMessage={renderNoOptionsMessage}
            createOptionPosition="first"
            formatCreateLabel={renderCreateOptionLabel}
            {...rest}
        />
    );
};
