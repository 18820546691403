import { ClassNamesConfig, StylesConfig } from 'react-select';

import { SelectOption } from '../../Select/types';

import styles from '../../Select/SelectStyles.module.scss';

export const classNames: ClassNamesConfig<SelectOption, true> = {
    menu: () => styles.menu,
    menuList: () => styles.menuList,
    control: () => styles.control,
    container: () => styles.container,
    indicatorsContainer: () => styles.indicatorsContainer,
    indicatorSeparator: () => styles.indicatorSeparator,
    valueContainer: () => styles.valueContainer,
    multiValue: () => styles.multiValue,
    multiValueLabel: () => styles.multiValueLabel,
    multiValueRemove: () => styles.multiValueRemove,
};

export const getStyles: StylesConfig<SelectOption, true> = {
    dropdownIndicator: (base, { isFocused, selectProps }) => {
        const rotate = isFocused && selectProps.menuIsOpen ? '180deg' : '0deg';
        return { ...base, transform: `rotate(${rotate})`, transition: 'all 0.1s ease-in' };
    },
};
