import { ILoginResponse } from '@frontegg/rest-api';

import { getLogger } from '../../../../utils/logger';
import { CACHED_TOKEN_KEY } from '../../../storage/storage-keys-list';
import { AbstractEventHandler } from '../abstract-event-handler';

const logger = getLogger('OnTokenUpdatedEvent');

export class OnTokenUpdatedEvent extends AbstractEventHandler {
    register() {
        chrome.storage.onChanged.addListener((changes, namespace) => {
            if (
                changes[CACHED_TOKEN_KEY.getValue()] &&
                changes[CACHED_TOKEN_KEY.getValue()].oldValue !== changes[CACHED_TOKEN_KEY.getValue()].newValue
            ) {
                const newValue: ILoginResponse | undefined = changes[CACHED_TOKEN_KEY.getValue()].newValue;
                if (newValue && typeof newValue === 'object' && newValue?.accessToken) {
                    logger.log('calling onTokenUpdated with new token');
                    this.emitter.emit('onTokenUpdated', undefined, newValue.accessToken);
                }
            }
        });
    }
}
