import { isWebMode } from '../../utils/extension-mode';
import { getLogger } from '../../utils/logger';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

import { IStorageKey } from './types';

const logger = getLogger('Storage Service');

const getStorageValueSync = <T>(valueKey: string, defaultValue: T): T => {
    try {
        const serializedValue = localStorage.getItem(valueKey);
        if (serializedValue === null) {
            return defaultValue;
        }

        const value = JSON.parse(serializedValue);

        return value;
    } catch (e) {
        return defaultValue;
    }
};

const getStorageValue = async <T>(valueKey: string, defaultValue: T): Promise<T> => {
    if (isWebMode()) {
        return getStorageValueSync(valueKey, defaultValue);
    } else {
        const values = await getStorageValues([valueKey]);

        if (values[valueKey] === null) {
            return defaultValue;
        }

        return values[valueKey];
    }
};

const getStorageValues = async (keys: string[]): Promise<Record<string, any>> => {
    const result: Record<string, any> = {};

    if (isWebMode()) {
        keys.forEach((key) => {
            const value = getStorageValueSync(key, null);

            result[key] = value;
        });

        return result;
    } else {
        try {
            const serializedValues = await chrome.storage.local.get(keys);

            keys.forEach((key) => {
                if (serializedValues[key] === undefined) {
                    result[key] = null;
                } else {
                    try {
                        result[key] = JSON.parse(serializedValues[key]);
                    } catch (e) {
                        logger.error(
                            `An error has occurred while parsing storage value for key - ${key}`,
                            e,
                            serializedValues[key]
                        );
                    }
                }
            });
        } catch (e) {
            logger.error('An error has occurred while reading storage values', e, keys);
        }

        return result;
    }
};

const setStorageValue = async <T>(valueKey: string, value: T) => {
    const valueSerialized = JSON.stringify(value);

    if (isWebMode()) {
        localStorage.setItem(valueKey, valueSerialized);
    } else {
        await chrome.storage.local.set({ [valueKey]: valueSerialized });
    }
};

const removeStorageValue = async (valueKey: string | string[]) => {
    const keys = Array.isArray(valueKey) ? valueKey : [valueKey];

    if (isWebMode()) {
        keys.forEach((key) => localStorage.removeItem(key));
    } else {
        await chrome.storage.local.remove(valueKey);
    }
};

const resetStorage = async () => {
    if (isWebMode()) {
        localStorage.clear();
    } else {
        await chrome.storage.local.clear();
    }
};

const service = createWorkerService({
    name: 'storage',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        getStorageValue,
        getStorageValues,
        setStorageValue,
        removeStorageValue,
        resetStorage,
    }),
});

export const storageService = {
    getStorageValue: async <T>(key: IStorageKey, defaultValue: T): Promise<T> => {
        return service.actions.getStorageValue(key.getValue(), defaultValue);
    },
    getStorageValues: async (keys: IStorageKey[]): Promise<Record<string, any>> => {
        const stringKeys: string[] = keys.map((key) => key.getValue());

        return service.actions.getStorageValues(stringKeys);
    },
    setStorageValue: async <T>(key: IStorageKey, value: T) => {
        return service.actions.setStorageValue(key.getValue(), value);
    },
    removeStorageValue: async (keys: IStorageKey | IStorageKey[]) => {
        const stringKeys: string[] = Array.isArray(keys) ? keys.map((key) => key.getValue()) : [keys.getValue()];

        return service.actions.removeStorageValue(stringKeys);
    },
    resetStorage: service.actions.resetStorage,
    _passthrough: {
        getStorageValue: async <T>(key: IStorageKey, defaultValue: T): Promise<T> => {
            return service.actions._passthrough.getStorageValue(key.getValue(), defaultValue);
        },
        getStorageValues: async (keys: IStorageKey[]): Promise<Record<string, any>> => {
            const stringKeys: string[] = keys.map((key) => key.getValue());

            return service.actions._passthrough.getStorageValues(stringKeys);
        },
        setStorageValue: async <T>(key: IStorageKey, value: T) => {
            return service.actions._passthrough.setStorageValue(key.getValue(), value);
        },
        removeStorageValue: async (keys: IStorageKey | IStorageKey[]) => {
            const stringKeys: string[] = Array.isArray(keys) ? keys.map((key) => key.getValue()) : [keys.getValue()];

            return service.actions._passthrough.removeStorageValue(stringKeys);
        },
        resetStorage: service.actions._passthrough.resetStorage,
    },
};

export const registerStorageService = service.register;
