import { arrayBufferToBase64 } from '../../utils/preview';

import { IBackgroundFetchResponse } from './types';

export const normalizeHeaders = (headersInit: RequestInit['headers']) => {
    const headers = headersInit instanceof Headers ? headersInit : new Headers(headersInit);

    return Object.fromEntries(headers.entries());
};

export const normalizeRequestUrl = (requestInfo: RequestInfo | URL): string => {
    if (requestInfo instanceof URL) {
        return requestInfo.toString();
    } else if (typeof requestInfo === 'string') {
        return requestInfo;
    }
    return requestInfo.url;
};

export const getBackgroundFetchResponse = async (fetchResponse: Response): Promise<IBackgroundFetchResponse> => {
    const options: IBackgroundFetchResponse['options'] = {
        ok: fetchResponse.ok,
        type: fetchResponse.type,
        status: fetchResponse.status,
        statusText: fetchResponse.statusText,
        headers: Object.fromEntries(fetchResponse.headers.entries()),
    };

    if (!fetchResponse.ok) {
        return { options };
    }

    let data: IBackgroundFetchResponse['data'];
    const contentType = fetchResponse.headers.get('content-type') ?? '';
    switch (true) {
        case contentType.includes('application/json'): {
            data = await fetchResponse.json();
            break;
        }
        case contentType.includes('text/plain'): {
            data = await fetchResponse.text();
            break;
        }
        case contentType.includes('application/pdf'): {
            const arrayBuffer = await fetchResponse.arrayBuffer();
            // will convert to base64 string
            data = await arrayBufferToBase64(arrayBuffer);
            break;
        }
        case contentType.includes('text/event-stream'): {
            data = fetchResponse?.body;
            break;
        }

        default: {
            data = null;
            break;
        }
    }

    return { data, options };
};
