import { DatesFilterRelativeName, IDateFilter } from '../api/types';

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_DATA_ANALYST = 'data-analyst';
export const USER_ROLE_KNOWLEDGE_MANAGER = 'KnowledgeManager';
export const ADMIN_PANEL_LINK_USER_ROLES_WHITE_LIST = [
    USER_ROLE_ADMIN,
    USER_ROLE_DATA_ANALYST,
    USER_ROLE_KNOWLEDGE_MANAGER,
];

export const SHARED_ANSWERS_CACHE_KEY = 'shared-answers-post';
export const SHARED_PROACTIVE_ANSWERS_CACHE_KEY = 'shared-proactive-answers-post';
export const REPLACE_STATE_ACTION = 'REPLACE_APP_STATE';
export const DEFAULT_SEARCH_STATE_RELEVANCE_TIME = 1000 * 60 * 60; // 1 hour
export const PROACTIVE_DEFAULT_USER_SCORE = 0.5;
export const DEFAULT_LANGUAGE_CODE = 'en';
export const DEFAULT_SELECTED_SMART_FILTERS = [];
export const DEFAULT_FLOATING_ICON_HIDE_DELAY_MS = 10_000;
export const ALL_TIME_DATES_FILTER: IDateFilter = {
    relative_name: DatesFilterRelativeName.ANY_TIME,
};
