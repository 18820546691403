import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { environmentBackend, PROACTIVE_FREQUENCY_GROUP_UNSPECIFIED } from '../../../api/consts';
import { AppsVersion, IBaseApiSettings } from '../../../api/types';
import {
    ALL_TIME_DATES_FILTER,
    DEFAULT_FLOATING_ICON_HIDE_DELAY_MS,
    DEFAULT_LANGUAGE_CODE,
    DEFAULT_SELECTED_SMART_FILTERS,
    PROACTIVE_DEFAULT_USER_SCORE,
} from '../../constants';
import {
    saveBackendUrl,
    setDebugMode,
    setDisabledSources,
    setDisableOnlineMemory,
    setProject,
    setSelectedDatesFilter,
    setSelectedSmartFilters,
} from '../../thunks/settings-thunk';
import { logout, setUser } from '../../thunks/user-thunk';
import { ISettingsState, IStateProactiveFrequencyGroup } from '../../types';

const initialState: ISettingsState = {
    disabledSources: [], // will be loaded asynchronously in App Init middleware
    backendUrl: environmentBackend, // will be loaded asynchronously in App Init middleware
    project: '', // will be loaded asynchronously in App Init middleware
    confidenceSettings: {
        gray_out: 0,
        absolute: 0,
        relative: 0,
    },
    disableOnlineMemory: null, // will be loaded asynchronously in App Init middleware
    debugMode: null, // will be loaded asynchronously in App Init middleware
    shouldDisplayFloatingApp: null, // will be loaded asynchronously in App Init middleware
    originsBlacklist: [], // will be loaded asynchronously in App Init middleware
    simulateCustomerProject: '',
    fullTicketAppUrl: '',
    searchStateRelevanceTime: 0,
    proactiveFrequencySettings: {
        groups: [],
        timeouts: {
            not_viewed_timeout: 30000,
            debounce_delay: 3000,
        },
    },
    proactiveFrequencyCurrentGroup: {
        name: PROACTIVE_FREQUENCY_GROUP_UNSPECIFIED,
        score: PROACTIVE_DEFAULT_USER_SCORE,
    },
    languageCode: DEFAULT_LANGUAGE_CODE,
    currentUrl: '',
    selectedSmartFilters: DEFAULT_SELECTED_SMART_FILTERS,
    floatingIconHideDelayMs: DEFAULT_FLOATING_ICON_HIDE_DELAY_MS,
    selectedDatesFilter: ALL_TIME_DATES_FILTER,
    apps_version: AppsVersion.APPS_V2,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setBackendUrl(state, action: PayloadAction<string>) {
            const { payload } = action;

            state.backendUrl = payload;
        },
        setConfidenceSettings(state, action: PayloadAction<ISettingsState['confidenceSettings']>) {
            state.confidenceSettings = action.payload;
        },
        setShouldDisplayFloatingApp(state, action: PayloadAction<boolean>) {
            state.shouldDisplayFloatingApp = action.payload;
        },
        setApiGatewayUrl(state, action: PayloadAction<ISettingsState['gatewayUrl']>) {
            state.gatewayUrl = action.payload;
        },
        setSimulateCustomerProject(state, action: PayloadAction<ISettingsState['simulateCustomerProject']>) {
            state.simulateCustomerProject = action.payload;
        },
        setProactiveSimulateUrl(state, action: PayloadAction<ISettingsState['fullTicketAppUrl']>) {
            state.fullTicketAppUrl = action.payload;
        },
        // please, use thunk instead
        setSearchStateRelevanceTime(state, action: PayloadAction<ISettingsState['searchStateRelevanceTime']>) {
            state.searchStateRelevanceTime = action.payload;
        },
        setProactiveFrequencySettings(state, action: PayloadAction<IBaseApiSettings['proactive_frequency']>) {
            const { groups, timeouts } = action.payload;

            // using reverse order since we don't have threshold ranges, only max value
            const groupsSorted: IStateProactiveFrequencyGroup[] = Object.entries(groups)
                .map(([name, settings]) => ({
                    name,
                    ...settings,
                }))
                .sort((a, b) => b.threshold - a.threshold);

            state.proactiveFrequencySettings = {
                groups: groupsSorted,
                timeouts,
            };
        },
        setCurrentUrl(state, action: PayloadAction<ISettingsState['currentUrl']>) {
            state.currentUrl = action.payload;
        },
        _setProactiveFrequencyGroup(state, action: PayloadAction<ISettingsState['proactiveFrequencyCurrentGroup']>) {
            state.proactiveFrequencyCurrentGroup = action.payload;
        },
        // use thunk instead
        _setLanguageCode(state, action: PayloadAction<string>) {
            state.languageCode = action.payload;
        },
        // use thunk instead
        _setOriginsBlacklist(state, action: PayloadAction<ISettingsState['originsBlacklist']>) {
            state.originsBlacklist = action.payload;
        },

        setFloatingIconHideDelay(state, action: PayloadAction<ISettingsState['floatingIconHideDelayMs']>) {
            state.floatingIconHideDelayMs = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setDisabledSources.fulfilled, (state, action) => {
            state.disabledSources = action.payload;
        });
        builder.addCase(setSelectedSmartFilters.fulfilled, (state, action) => {
            state.selectedSmartFilters = action.payload;
        });
        builder.addCase(setSelectedDatesFilter.fulfilled, (state, action) => {
            state.selectedDatesFilter = action.payload;
        });
        builder.addCase(setProject.fulfilled, (state, action) => {
            state.project = action.payload;
            state.disabledSources = [];
            state.selectedDatesFilter = ALL_TIME_DATES_FILTER;
        });
        builder.addCase(saveBackendUrl.fulfilled, (state, action) => {
            const { payload } = action;
            if (payload === '') {
                state.backendUrl = environmentBackend;
            } else {
                state.backendUrl = payload;
            }
        });
        builder.addCase(setDisableOnlineMemory.fulfilled, (state, action) => {
            state.disableOnlineMemory = action.payload;
        });
        builder.addCase(setDebugMode.fulfilled, (state, action) => {
            state.debugMode = action.payload;
        });
        builder.addCase(setUser.fulfilled, (state, action) => {
            const { disableOnlineMemory } = action.payload;

            if (disableOnlineMemory) {
                state.disableOnlineMemory = false;
            }
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.disabledSources = [];
            state.project = '';
            state.proactiveFrequencyCurrentGroup = initialState.proactiveFrequencyCurrentGroup;
            state.languageCode = initialState.languageCode;
            state.simulateCustomerProject = initialState.simulateCustomerProject;
            state.fullTicketAppUrl = initialState.fullTicketAppUrl;
            state.currentUrl = window.location.href;
        });
    },
});

export const {
    setConfidenceSettings,
    setShouldDisplayFloatingApp,
    setApiGatewayUrl,
    setSimulateCustomerProject,
    setProactiveSimulateUrl,
    setSearchStateRelevanceTime,
    setProactiveFrequencySettings,
    setCurrentUrl,

    setFloatingIconHideDelay,
    _setProactiveFrequencyGroup,
    _setLanguageCode,
    _setOriginsBlacklist,
} = settingsSlice.actions;
