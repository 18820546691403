import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

const passKeyDownEvent = async (event: any, frameId?: string) => {
    const { eventBusEmitter } = await import(
        /* webpackMode: "eager" */
        '../core/event-bus/event-bus-emitter'
    );
    eventBusEmitter.emit('preview-keydown', undefined, { event, frameId });
};

const service = createWorkerService({
    name: 'preview-keydown',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        passKeyDownEvent,
    }),
});

export const previewPassKeydownEventService = service.actions;
export const registerPreviewPassKeydownEventService = service.register;
