import React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { FastField, FieldProps, getIn } from 'formik';

import { CollectionSelectOption } from '../../AddKnowledgeModal/types';

import { CollectionsMultiSelectInput } from './components/CollectionsMultiSelectInput';

export const Collections: React.FC = () => {
    const { t } = useTranslation('translations');
    return (
        <FastField name="collections">
            {({ field, form }: FieldProps<CollectionSelectOption[]>) => {
                const isInvalid = getIn(form.errors, field.name) && getIn(form.touched, field.name);

                const onChange = (newValue: MultiValue<CollectionSelectOption>): void => {
                    form.setFieldValue(field.name, newValue);
                };

                return (
                    <FormControl isInvalid={isInvalid}>
                        <FormLabel variant="add-knowledge">{t('ask-ai-kb.collections')}</FormLabel>
                        <CollectionsMultiSelectInput
                            id={field.name}
                            name={field.name}
                            isInvalid={isInvalid}
                            onBlur={field.onBlur}
                            onChange={onChange}
                            value={field.value}
                        />
                    </FormControl>
                );
            }}
        </FastField>
    );
};
