import { batch } from 'react-redux';

import { MixpanelEvent } from '../../services/mixpanel/types';
import { parseDirectAnswerTextFootnotes } from '../../utils/direct-answer';
import { AppThunk } from '../core-store';
import {
    _closeAskAiAddKnowledgeModal,
    _openAskAiAddKnowledgeModal,
    IAskAiKbModalSliceState,
} from '../slices/modals/ask-ai-kb-modal-slice';

import { sendMetrics } from './metrics-thunk';

export const closeAskAiAddKnowledgeModal = (): AppThunk => (dispatch) => {
    batch(() => {
        dispatch(_closeAskAiAddKnowledgeModal());
        dispatch(sendMetrics({ event: MixpanelEvent.ASK_AI_KB_CLOSE_ADD_KNOWLEDGE_MODAL }));
    });
};

/**
 * Opens the Ask-Ai add knowledge modal and sets the initial form values
 * in case title is not provided, it will use the current question from the state
 *
 * @param payload
 * @param meta
 */
export const openAskAiAddKnowledgeModal =
    (payload: Partial<IAskAiKbModalSliceState['initFormValues']>, meta?: Record<string, unknown>): AppThunk =>
    (dispatch, getState) => {
        let _payload: IAskAiKbModalSliceState['initFormValues'] = null;

        if (payload) {
            const title = payload.title ?? getState().question.question;
            const { clean } = parseDirectAnswerTextFootnotes(payload.answer ?? '');

            _payload = {
                title,
                answer: clean,
            };
        }

        batch(() => {
            dispatch(_openAskAiAddKnowledgeModal(_payload));
            dispatch(sendMetrics({ event: MixpanelEvent.ASK_AI_KB_OPEN_ADD_KNOWLEDGE_MODAL, meta }));
        });
    };
