import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDirectAnswerEditModalState {
    visible: boolean;
    content?: string;
}

const initialState: IDirectAnswerEditModalState = {
    visible: false,
};

export const directAnswerEditModalSlice = createSlice({
    name: 'direct-answer-edit-modal',
    initialState,
    reducers: {
        openDirectAnswerEditModal(state, action: PayloadAction<string>) {
            if (!action.payload) {
                return;
            }
            state.content = action.payload;
            state.visible = true;
        },
        closeDirectAnswerEditModal(state) {
            state.visible = false;
            state.content = undefined;
        },
    },
});

export const { openDirectAnswerEditModal, closeDirectAnswerEditModal } = directAnswerEditModalSlice.actions;
